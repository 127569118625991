import * as React from "react"
import { Container, Box, Grid, Typography, makeStyles } from "@material-ui/core"
import Seo from "../../../components/seo"
import Menu from "../../../components/festival/menu"
import Band from "../../../components/festival/band"
import { StaticImage } from "gatsby-plugin-image"
import {Link} from 'gatsby'

const momoyo = <>In Momoyo werden een heleboel ijzersterke muzikanten samengegooid en daaruit groeide een prachtige midnight pop. Na samenwerkingen met Noémie Wolfs en Galine vonden deze artiesten uiteindelijk ook elkaar. Met nummers als Breath en Skin begeven ze zich in intieme sferen en de soulfulle vocals en opzwepende snaarinstrumenten zuigen je zonder enig weerwoord mee de diepte in. </>

const momoyoImage =  <StaticImage 
src="../../../images/festival/donderdag/momoyo.png" 
alt="momoyo"
placeholder="blurred"
/> 

const schooliscool = <>ENERGIEK ZWETEN is nooit weggeweest. School is Cool brengt na meer dan 10 jaar een ervaren diepte mee op het podium. Sinds hun succes op Humo’s Rock Rally hebben ze niet stilgezeten! Ze scoorden met hits als New Kids in Town, The World Is Gonna End Tonight en recenter ook met Close. Ook in hun laatste Album slaagt de band erin om sombere thema’s aan te snijden met een onuitputtelijk vat vol levenslust en barokke ritmes. Stilletjes op je stoel blijven zitten wordt gegarandeerd een uitdaging.<br/><br/></>

const schooliscoolImage =  <StaticImage 
src="../../../images/festival/donderdag/school-is-cool.png" 
alt="School is Cool"
placeholder="blurred"
/>  


const useStyles = makeStyles(theme => ({
  pijlRechts: {
      float: 'right',
  },
  pijlLinks: {
    float: 'left',
},
  title:{
    textAlign: "center"
  }
 }));


const VrijdagAvondPage = () => {
  const classes = useStyles();
  return (

    <Container maxWidth="lg">
      <Seo title="Tomatostories - Lineup donderdag" />
      <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Menu/>
            </Grid>
            <Grid item xs={12} className={classes.title}>
              <Typography variant="h3" component="h1" color="primary">
              <Link to="/festival/lineup/woensdag">
                  <StaticImage 
                      src="../../../images/pijl-links.png" 
                      alt="woensdag 18/08" 
                      placeholder="tracedSVG"
                      height={60}
                      className={classes.pijlLinks}
                      />
                 </Link>
                donderdag 19/08
                <Link to="/festival/lineup/vrijdagmiddag">
                  <StaticImage 
                      src="../../../images/pijl-rechts.png" 
                      alt="Vrijdagmiddag 20/08" 
                      placeholder="tracedSVG"
                      height={60}
                      className={classes.pijlRechts}
                      />
                 </Link>
              </Typography>
              
            </Grid>
            <Band text={momoyo} time="19u" title="momoyo" image={momoyoImage} orientation="left"/>
            <Band text={schooliscool} time="21u" title="School is Cool" image={schooliscoolImage} orientation="right"/>
          </Grid>
        </Box>    
      </Container>

)}

export default VrijdagAvondPage
