import * as React from "react"
import {Box, makeStyles, Typography, Grid} from '@material-ui/core';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    box: {
        float:'right',
        clear: 'both'
    },
    menu:{
        float:'right',
        listStyle: "none",
    },
    menuItem: {
        display: "block",
        float: "left",
        marginRight: theme.spacing(2)
    },
    text: {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: {
            fontSize: '1em',
          }
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.main
    },
    activeLink: {
        color: theme.palette.secondary.main
    }
  }));

const MenuItem = ({text, link}) => {
    const classes = useStyles();
    return ( 
    <li className={classes.menuItem}>
        <Typography variant="h5" className={classes.text}>
            <Link 
            to={link} 
            className={classes.link} 
            activeClassName={classes.activeLink}     
            partiallyActive={true}>
                {text}
            </Link>
        </Typography>
    </li>
   )
}
  

const Menu = () => {
    const classes = useStyles();
    return (
        <Box className={classes.box}>
        <Grid container>
            <Grid item xs={12}>
            <ul className={classes.menu}>
                <MenuItem text="LINE-UP" link="/festival/lineup"/>
                <MenuItem text="TICKETS" link="/festival/tickets"/>
                <MenuItem text="PRAKTISCH" link="/festival/praktisch"/>
            </ul>
            </Grid>
        </Grid>
          
            
    </Box>
    )
}

MenuItem.propTypes = {
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
}

export default Menu