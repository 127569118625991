import * as React from "react"
import { Grid} from '@material-ui/core';
import PropTypes from 'prop-types';
import TextBox from "../textBox"

const LeftBand = ({text, image, title, time, orientation}) => {
    return (
        <>
            <Grid item md={8} xs={12}>
                {image}  
            </Grid>
            <Grid item md={4} xs={12}>
                <TextBox text={text} title={title} time={time} orientation={orientation}/>
            </Grid>
        </>  
    )
}

const RightBand = ({text, image, title, time, orientation}) => {
    return (
        <>
            <Grid item md={4} xs={12}>
                <TextBox text={text} title={title} time={time} orientation={orientation}/>
            </Grid>
            <Grid item md={8} xs={12}>
               {image}  
            </Grid>
        </>  
    )
}

const Band = ({text, image, title, time, orientation}) => {

   if(orientation === "left")
    return <LeftBand text={text} title={title} image={image} time={time} orientation={orientation}/>

   return <RightBand text={text} title={title} image={image} time={time} orientation={orientation}/>
}

Band.propTypes = {
    text: PropTypes.any.isRequired,
    image: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    orientation: PropTypes.oneOf(['left','right'])
}

RightBand.propTypes = {
    text: PropTypes.any.isRequired,
    image: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    orientation: PropTypes.oneOf(['left','right'])
}

LeftBand.propTypes = {
    text: PropTypes.any.isRequired,
    image: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    orientation: PropTypes.oneOf(['left','right'])
}
export default Band

