import * as React from "react"
import {makeStyles, Box, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    textBox: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(4),
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      height: "100%"
    },
    left: {
      clipPath: "polygon(0 0, 100% 0%, 100% 84%, 0% 100%)"

    },
    right: {
      clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0% 84%)"

    },
    boxText: {
      color: theme.palette.background.default,
      fontWeight: 800
    },
  }));

const TextBox = ({text, orientation, title, time}) => {

    const classes = useStyles();
    return (
        <Box className={clsx(classes.textBox, orientation === "left" && classes.left, orientation === "right" && classes.right)}>
            <Typography variant="h6" className={classes.boxText}>{time}&nbsp;&nbsp;{title}</Typography><br/>
            <Typography className={classes.boxText} variant="body2">
                {text}
            </Typography>
        </Box>
    )
}

TextBox.propTypes = {
    text: PropTypes.node.isRequired,
    orientation: PropTypes.oneOf(['left','right']),
    title: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired
}

export default TextBox

